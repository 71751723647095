import React from "react";
import "./Style.css";
function EnvioRealizado(props) {
  return (
    <header>
      <center>
        <a className="id_link_envio_realizado" href="https://extranet.clamed.com.br/link.php?p=1">
          <div className="id_envio_realizado">
            <span className="id_conteudo_envio_realizado">
              A sua receita foi enviada com sucesso.
            </span>
            <br />
            <br />
            <span className="id_conteudo_envio_realizado">
              Para medicamentos controlados, ser&aacute; feita a
              verifica&ccedil;&atilde;o da assinatura digital, conforme a
              determina&ccedil;&atilde;o da anvisa:
            </span>
            <br />
            <br />
            <span className="id_conteudo_envio_realizado">
                https://extranet.clamed.com.br/link.php?p=1
            </span>
            <br />
            <br />
            <span className="id_conteudo_envio_realizado">
              Confira a valida&ccedil;&atilde;o da assinatura com nosso
              farmac&ecirc;utico.
            </span>
          </div>
        </a>
      </center>
    </header>
  );
}

export default EnvioRealizado;
